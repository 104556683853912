<template>
  <div>
    <el-table :border="true" :data="auditHistoryList.list" class="br-table" style="">
      <el-table-column label="申请编号" prop="applyCode" />
      <el-table-column label="当前节点" prop="nodeName" />
      <el-table-column label="审核人" prop="approveName" />
      <el-table-column label="审核意见" prop="approveResult">
        <template slot-scope="scope">
          {{ $code("approveResult",scope.row.approveResult) }}
        </template>
      </el-table-column>
      <el-table-column label="审核说明" prop="approveAdvise" />
      <el-table-column label="审核时间" prop="approveTime">
        <template
          slot-scope="scope"
        >
          {{ scope.row.approveTime?moment(scope.row.approveTime).format("YYYY-MM-DD HH:mm:ss"):"" }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import { getAuditHistory } from '@/api/tradeFinancing.js'
export default {
  data() {
    return {
      moment,
      auditHistoryList: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getAuditHistory({
        applyCode: this.$route.query.financeNo
      }, res => {
        this.auditHistoryList = res.data
      })
    }
  }
}
</script>
